import lang from "quasar/lang/en-US.js"
import iconSet from "quasar/icon-set/material-icons.js"
import { Notify,Loading,Dialog,Dark } from "quasar"
import { QSelect } from "quasar"

export const componentsWithDefaults = { QSelect }

export const appConfigKey = "nuxtQuasar"

export const quasarNuxtConfig = {
  lang,
  iconSet,
  components: {"defaults":{"QSelect":{"optionsSelectedClass":"bg-primary text-white"}}},
  plugins: {Notify,Loading,Dialog,Dark},
  
}