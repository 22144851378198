<template>
  <q-dialog v-model="dialogOpen">
    <q-card>
      <CardHeader icon="mdi-alert-box-outline">
        {{ dialogFields?.title || "Are you sure?" }}
      </CardHeader>
      <slot>
        <q-card-section class="q-mb-sm whitespace">
          {{ dialogFields?.body || undefined }}
        </q-card-section>
      </slot>
      <q-separator />
      <q-card-actions>
        <q-checkbox
          v-if="dialogFields?.showDontShowAgain"
          v-model="dontShowAgain"
          label="Don't show again"
        />
        <q-space />
        <q-btn
          flat
          :label="dialogFields?.cancelButton || 'Cancel'"
          @click="_cancel()"
        />
        <q-btn
          :color="dialogFields?.primaryButtonColour ?? 'primary'"
          data-cy="dialogConfirm"
          :label="dialogFields?.confirmButton || 'Confirm'"
          @click="_confirm()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { useConfirmDialog } from "~/composables/useConfirmDialog";
import { CardHeader } from "#components";

const { dialogOpen, _confirm, _cancel, dontShowAgain, dialogFields } =
  useConfirmDialog();
</script>
