import {
  PathContextBuilder,
  type PathContextBuilder as PathContextBuilderType,
} from "#imports";
import type { InternalApi } from "nitropack";
import type { Prettify } from "~/utils/helpers";

export type BackendBookingViewModel = Prettify<
  InternalApi["/api/hcd/booking/:bookingId"]["get"]
>;
export type Parties = BackendBookingViewModel["parties"];

export type BackendBookingCTUViewModel = Prettify<
  BackendBookingViewModel["cargo-transport-units"][number]
>;

export type WithUnknownExtraProperties<T> = T & {
  [key: string]: string | number | boolean | undefined;
};

type BookingCargo = BackendBookingCTUViewModel["cargo"][number];

export type BackendBookingCargoViewModel = Prettify<BookingCargo>;

export const isMetadataBookingKey = (key: string) => {
  return key.startsWith("$");
};

export const shouldShowBookingProperty = (key: string) => {
  if (
    key === SAILINGS_KEY_IN_BOOKING_VIEW_MODEL ||
    key === PARTIES_KEY_IN_BOOKING_VIEW_MODEL ||
    key === CONTAINERS_KEY_IN_BOOKING_VIEW_MODEL
  )
    return false;
  if (isMetadataBookingKey(key)) return false;

  return true;
};

const PATH_ROOT = "$";

export const bookingPathMatchesField = (path: string, field: string) => {
  if (path !== PATH_ROOT) {
    return field.startsWith(path);
  }
  // path at top level of booking, so we need to filter out keys

  const fieldParts = field.split(".");
  if (fieldParts.length < 2) return false;
  let key = fieldParts[1];

  // if it's an array access, remove it e.g. cargo-transport-units[0]
  const indexOfArrayAccessAttempt = key.indexOf("[");
  if (indexOfArrayAccessAttempt > -1) {
    key = key.substring(0, indexOfArrayAccessAttempt);
  }

  const result = shouldShowBookingProperty(key);

  return result;
};

export type BookingCargoPackagingViewModel = Prettify<
  Required<BackendBookingCTUViewModel["cargo"][number]>["packaging"]
>;

export const SAILINGS_KEY_IN_BOOKING_VIEW_MODEL = "sailings";
export type BackendBookingSailingViewModel = Prettify<
  BackendBookingViewModel[typeof SAILINGS_KEY_IN_BOOKING_VIEW_MODEL][number]
>;

export type BackendBookingSailingStageViewModel = Prettify<
  BackendBookingSailingViewModel["stages"][number]
>;

export const PARTIES_KEY_IN_BOOKING_VIEW_MODEL = "parties";
export type BookingPartyViewModel = Prettify<
  BackendBookingViewModel[typeof PARTIES_KEY_IN_BOOKING_VIEW_MODEL][number]
>;

export const CONTAINERS_KEY_IN_BOOKING_VIEW_MODEL = "cargo-transport-units";

type HasId = { $id?: string };

const createIndexOrIdPath = (
  x: HasId,
  index: number,
  path: PathContextBuilder
) => {
  if (x.$id) return path.withKeyValueArray("$id", x.$id);
  else return path.withIndex(index);
};

abstract class BaseModel<T extends HasId> {
  path: PathContextBuilderType;

  constructor(
    public _raw: T,
    public displayName: string,
    path: PathContextBuilderType,
    public index: number,
    propertyKey: string
  ) {
    this.path = createPath(_raw, index, path, propertyKey);
  }
}

export class BookingViewModel {
  _raw: BackendBookingViewModel;
  containers: BookingContainerViewModel[];
  sailings: BookingSailingViewModel[];
  path = new PathContextBuilder();

  /** Can be different from the case id */
  bookingId: string | null;

  constructor(booking: BackendBookingViewModel) {
    this._raw = booking;
    this.bookingId = booking.reference || null;
    this.containers = booking["cargo-transport-units"].map(
      (x, i) => new BookingContainerViewModel(x, i, this.path)
    );
    this.sailings = booking.sailings.map(
      (x, i) => new BookingSailingViewModel(x, i, this.path)
    );
  }
}

function createPath(
  obj: HasId,
  indexInBooking: number,
  path: PathContextBuilderType,
  propertyKey: string
) {
  const p = new PathContextBuilder(path).withProperty(propertyKey);
  return createIndexOrIdPath(obj, indexInBooking, p);
}

const createCargo = (
  cargo: BackendBookingCargoViewModel[],
  path: PathContextBuilderType
) => cargo.map((x, i) => new BookingCargoViewModel(x, i, path));

export class BookingContainerViewModel extends BaseModel<BackendBookingCTUViewModel> {
  cargo: BookingCargoViewModel[];

  constructor(
    container: BackendBookingCTUViewModel,
    indexInBooking: number,
    path: PathContextBuilderType
  ) {
    const displayName = container.$id ?? `Container ${indexInBooking + 1}`;
    super(
      container,
      displayName,
      path,
      indexInBooking,
      CONTAINERS_KEY_IN_BOOKING_VIEW_MODEL
    );

    this.cargo = createCargo(container.cargo, this.path);
  }
}

export class BookingCargoViewModel extends BaseModel<BackendBookingCargoViewModel> {
  isDg: boolean;

  constructor(
    cargo: BackendBookingCargoViewModel,
    indexInContainer: number,
    path: PathContextBuilderType
  ) {
    const displayName = cargo.$id ?? `Cargo ${indexInContainer + 1}`;
    super(cargo, displayName, path, indexInContainer, "cargo");

    this.isDg = this.getIsDg();
  }

  private getIsDg() {
    return Boolean(
      "un-number" in this._raw ||
        ("declarations" in this._raw && this._raw.declarations?.length)
    );
  }
}

function getSailingDisplayName(
  sailing: BackendBookingSailingViewModel,
  indexInBooking: number
) {
  if (sailing.$id) return sailing.$id;
  if (sailing.type === "Cargo") {
    return `Sailing ${indexInBooking + 1}`;
  } else {
    return `Sailing ${indexInBooking + 1}`;
  }
}

function getVesselDisplayName(sailing: BackendBookingSailingViewModel) {
  return sailing.vessel.name || sailing.vessel.code;
}

export class BookingSailingViewModel extends BaseModel<BackendBookingSailingViewModel> {
  public stages: BookingSailingStageViewModel[];
  public type: "Cargo" | "Passenger";
  public vesselDisplayName: string;

  constructor(
    sailing: BackendBookingSailingViewModel,
    indexInBooking: number,
    path: PathContextBuilderType
  ) {
    const displayName = getSailingDisplayName(sailing, indexInBooking);
    super(sailing, displayName, path, indexInBooking, "sailings");

    this.type = sailing.type;
    this.vesselDisplayName = getVesselDisplayName(sailing);
    this.stages = sailing.stages.map(
      (x, i) => new BookingSailingStageViewModel(x, i, this.path)
    );
  }
}

function getSailingStageDisplayName(
  sailingStage: BackendBookingSailingStageViewModel,
  indexInSailing: number
) {
  if (sailingStage.$id) return sailingStage.$id;
  return `${sailingStage.type} Stage ${indexInSailing + 1}`;
}

export class BookingSailingStageViewModel extends BaseModel<BackendBookingSailingStageViewModel> {
  type: "Origin" | "Transit" | "Import" | "Export" | "Tranship" | "Final";
  public country: BackendBookingSailingStageViewModel["country"];

  constructor(
    sailingStage: BackendBookingSailingStageViewModel,
    indexInSailing: number,
    path: PathContextBuilderType
  ) {
    const displayName = getSailingStageDisplayName(
      sailingStage,
      indexInSailing
    );
    super(sailingStage, displayName, path, indexInSailing, "stages");

    this.country = sailingStage.country;
    this.type = sailingStage.type;
  }
}
