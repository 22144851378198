import { useState } from "#imports";
import { inject, provide, type Ref } from "vue";

const JSON_PATH_ROOT = "$";

export class PathContextBuilder {
  path: string[] = [JSON_PATH_ROOT];

  constructor(initial?: PathContextBuilder) {
    if (initial) {
      this.path = [...initial.path];
    }
  }

  provideContext() {
    provide(
      "bookingPath",
      useState(() => this.path)
    );
    return this;
  }

  fromContext() {
    const injectedValue = inject<Ref<string[]>>("bookingPath")?.value;
    if (!injectedValue) throw new Error("Path context not created");
    this.path = [...injectedValue];
    return this;
  }

  withProperty(path: string) {
    this.path.push(path);
    return this;
  }

  withIndex(index: number) {
    const last = this.path[this.path.length - 1];
    const base = last.replace(/\[\d+\]/, "");
    this.path[this.path.length - 1] = `${base}[${index}]`;
    return this;
  }

  withKeyValueArray(key: string, source: string) {
    const last = this.path[this.path.length - 1];
    const base = last.replace(/\[\?\s*]/, "");
    this.path[this.path.length - 1] = `${base}[?(@.${key} == '${source}')]`;
    return this;
  }

  build() {
    return this.path.join(".");
  }
}

export type Path = InstanceType<typeof PathContextBuilder>;
