import type { User } from "lucia";
import type { Resource, Roles, Scope } from "~/server/utils/roles";
import { ref, computed } from "vue";
import { useNuxtApp } from "#imports";
import { defineStore } from "pinia";

type Permission = Record<Scope, boolean>;

const ALL_SCOPES: Scope[] = [
  "read",
  "create",
  "update",
  "delete",
  "send-email",
  "screen",
  "import",
];
const ALL_RESOURCES: Resource[] = [
  "Case",
  "Library",
  "Rule",
  "Service-hook",
  "Hits",
  "Emailtemplate",
  "Booking",
  "Screen-results",
  "Policy",
  "Template",
  "Analytics",
  "Configuration",
  "inspectionrequest",
  "containerinspectioncompany",
];

const GET_DEFAULT_PERMISSION = () =>
  ALL_SCOPES.reduce((acc, x) => {
    acc[x] = false;
    return acc;
  }, {} as Permission);

const getEmptyDict = () =>
  ALL_RESOURCES.reduce((acc, x) => {
    acc[x] = GET_DEFAULT_PERMISSION();
    return acc;
  }, {} as PermissionDict);

type PermissionDict = Record<Resource, Permission>;

const REFRESH_INTERVAL = 1000 * 60 * 5;

export const useUserStore = defineStore("user", () => {
  const hasBeenInitialized = ref(false);

  const isLoggedIn = ref(false);
  const user = ref<null | User>(null);
  const roles = ref<Roles | null>(null);
  const teamName = ref<null | string>(null);
  const teamId = ref<null | string>(null);

  const mapPermissions = () => {
    const permission = getEmptyDict();

    for (const resource in roles.value) {
      if (!(resource in permission)) continue;

      const permissions = roles.value[resource as Resource];

      permissions.forEach((x) => {
        if (resource in permission) permission[resource as Resource][x] = true;
      });
    }
    return permission;
  };

  const hasPermission = computed(mapPermissions);

  const init = async () => {
    if (hasBeenInitialized.value) return createAuthResult();
    const result = await update();
    hasBeenInitialized.value = true;
    return result;
  };

  const createAuthResult = () => {
    return {
      user: user.value,
      roles: roles.value,
      teamName: teamName.value,
      teamId: teamId.value,
      isLoggedIn: isLoggedIn.value,
    };
  };

  const update = async () => {
    const { $api } = useNuxtApp();

    const response = await $api("/api/auth/user");

    user.value = response?.user || null;
    roles.value = response?.roles || null;
    teamName.value = response?.teamName?.at(0) || null;
    teamId.value = response?.team?.at(0) || null;

    isLoggedIn.value = !!response?.user;

    if (isLoggedIn.value) {
      startRefreshingToken();
    } else {
      stopRefreshingToken();
    }

    return createAuthResult();
  };

  const getLoginUrl = () => {
    return "/api/auth/login";
  };
  const getLogoutUrl = () => {
    return "/api/auth/logout";
  };

  const onSessionExpired = () => {
    console.log("Session Expired");

    stopRefreshingToken();

    if (import.meta.client) {
      const loc = window.location;

      loc.href = getLoginUrl();
    }
  };

  const refresher = ref<null | NodeJS.Timeout>(null);

  const startRefreshingToken = () => {
    if (refresher.value || import.meta.server) return;
    refresher.value = setInterval(
      () => refreshSession(),
      REFRESH_INTERVAL
    ) as NodeJS.Timeout;
  };

  const stopRefreshingToken = () => {
    if (!refresher.value) return;
    if (refresher.value) {
      clearInterval(refresher.value);
      refresher.value = null;
    }
  };

  const refreshSession = async () => {
    console.log("Refreshing Session...");
    const { $api } = useNuxtApp();

    const ok = await $api("/api/auth/refresh", {
      method: "POST",
      onResponseError: (e) => {
        if (!e.response.ok) onSessionExpired();
      },
    });

    if (!ok.ok) {
      await update();
    }
  };

  // sessionUpdator = setInterval(update, 1000 * 60 * 5);

  return {
    init,
    update,
    user,
    isLoggedIn,
    hasBeenInitialized,
    getLoginUrl,
    getLogoutUrl,
    roles,
    startRefreshingToken,
    stopRefreshingToken,
    hasPermission,
    teamName,
    teamId,
  };
});
